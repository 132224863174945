* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.rbc-header {
  border-left: none !important;
  border-right: none !important;
  padding: 15px 10px !important;
}

.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view {
  border-radius: 8px;
}

.rbc-day-bg:active,
.rbc-day-bg:hover {
  cursor: pointer;
  background-color: #feffde;
}
